<template>
  <v-row
    no-gutters
    class="mt-12"
    justify="space-between"
    align="center">
    <h2>
      {{ title }}
      <v-icon
        v-if="iconName"
        :color="iconColor"
        size="24">
        {{ iconName }}
      </v-icon>
    </h2>
    <p
      class="mb-0 d-flex align-center see-all-link"
      @click="switchView">
      {{ routeToTitle }}
    </p>
  </v-row>
</template>
<script>
export default {
  name: 'CollectionsSearchTitle',
  props: {
    titleIcon: {
      type: Object,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    criteria: {
      type: String,
      default: null,
    },
  },
  computed: {
    queryCriteria() {
      return this.$route.query.criteria;
    },
    routeToTitle() {
      return this.queryCriteria ?
        'collapsed view' : 'see all';
    },
    iconColor() {
      const { color } = this.titleIcon ?? {
      };
      return color;
    },
    iconName() {
      const { name } = this.titleIcon ?? {
      };
      return name;
    },
  },
  methods: {
    switchView() {
      this.$router.push({
        ...this.$route,
        query: {
          ...this.$route.query,
          ...(this.queryCriteria ? {
            criteria: undefined,
          } : {
            criteria: this.criteria,
          }),
        },
      }).then(() => {
        this.$nextTick(() => {
          this.$emit('switchView');
        });
      });

    },
  },
};
</script>
<style scoped lang="scss">
  .see-all-link {
    text-transform: uppercase;
    color: var(--v-lightBlue-base);
    cursor: pointer;
  }
</style>
